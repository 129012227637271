import { delay } from "utils/other";
import AxiosInstance from "auth/SQLInterceptor";

const SQLMyProjectsService = {};

SQLMyProjectsService.fetchProjects = async () => {
  try {
    await delay();
    const result = await AxiosInstance.get("/projects");
    return result.data.projects;
  } catch (error) {
    throw error;
  }
};

SQLMyProjectsService.deleteProject = async id => {
  try {
    await AxiosInstance.delete(`/projects/${id}`);
    return id;
  } catch (error) {
    throw error;
  }
};

SQLMyProjectsService.getProjectById = async id => {
  try {
    await delay();
    const result = await AxiosInstance.get(`/projects/${id}`);

    if (result?.data?.project) {
      return result?.data?.project;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

SQLMyProjectsService.addProject = async data => {
  try {
    const result = await AxiosInstance.post("/projects", data);

    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

SQLMyProjectsService.editProject = async data => {
  try {
    const result = await AxiosInstance.put(`/projects/${data.projectId}`, data);

    return result;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

SQLMyProjectsService.updateProjectSiteData = async (projectData, siteData) => {
  try {
    const projectId = projectData.projectId;

    const newData = await AxiosInstance.put(
      `/projects/website-settings/${projectId}`,
      siteData
    );
    console.log(`newData`, newData);
    return newData.data.website;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

SQLMyProjectsService.deleteProjectSiteData = async siteId => {
  try {
    // const projectId = projectData.id;

    const newData = await AxiosInstance.delete(
      `/projects/website-settings/${siteId}`
    );

    return newData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

SQLMyProjectsService.updateProjectSocialData = async (
  projectData,
  data,
  mode
) => {
  const projectId = projectData.projectId;

  try {
    switch (mode) {
      case "save":
        await AxiosInstance.post(
          `/projects/social-media-settings/${projectId}`,
          data
        );
        break;

      case "update":
        await AxiosInstance.put(
          `/projects/social-media-settings/${projectId}`,
          data
        );
        break;

      case "delete":
        await AxiosInstance.delete(
          `/projects/social-media-settings/${projectId}`,
          { data: data }
        );
        break;

      default:
        throw new Error("Invalid mode");
    }

    return true;
  } catch (error) {
    console.error("Error in updating social data:", error);
    throw error;
  }
};

SQLMyProjectsService.updateProjectSubjectData = async (
  projectData,
  data,
  mode
) => {
  const projectId = projectData.projectId;
  // console.log(`projectId`, projectId);

  try {
    let result = null;
    switch (mode) {
      case "save":
        result = await AxiosInstance.post(
          `/projects/content-generation-settings/${projectId}`,
          data
        );

        break;

      case "update":
        result = await AxiosInstance.put(
          `/projects/content-generation-settings/${projectId}`,
          data
        );
        break;

      case "delete":
        result = await AxiosInstance.delete(
          `/projects/content-generation-settings/${projectId}`,
          { data: { contentGenerationSettingsID: data.id } }
        );
        break;

      default:
        throw new Error("Invalid mode");
    }

    return result.data.result;
  } catch (error) {
    console.error("Error in updating subject data:", error);
    throw error;
  }
};

export default SQLMyProjectsService;
