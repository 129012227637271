import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  getDoc,
} from "firebase/firestore";

import { db } from "auth/FirebaseAuth";
import { AUTH_USER_DATA } from "constants/AuthConstant";
import AxiosInstance from "auth/SQLInterceptor";
import { delay } from "utils/other";

const FirebaseUserService = {};

FirebaseUserService.getUserData = async () => {
  try {
    if (!JSON.parse(localStorage.getItem(AUTH_USER_DATA))) return;
    const { owner_uid } = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    const userDataDocRef = doc(db, `users/${owner_uid}`);

    const userDataSnapshot = await getDoc(userDataDocRef);

    if (userDataSnapshot.exists()) {
      const userData = userDataSnapshot.data();
      return userData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

FirebaseUserService.getSubscriptionData = async subscription_id => {
  try {
    const subscriptionDocRef = doc(db, `subscriptions/${subscription_id}`);
    const subscriptionSnapshot = await getDoc(subscriptionDocRef);

    if (subscriptionSnapshot.exists()) {
      const subscriptionData = subscriptionSnapshot.data();

      return subscriptionData;
    } else {
      return null;
    }
  } catch (error) {
    throw error;
  }
};

FirebaseUserService.saveUserFCMToken = async token => {
  try {
    await delay();
    await AxiosInstance.post(`/user/add-fcm`, {
      fcmToken: token,
    });

    return true;
  } catch (error) {
    console.log(`Error in saveUserFCMToken`, error);
    return false;
  }
};

FirebaseUserService.updateUserData = async updatedData => {
  try {
    const response = await AxiosInstance.post(`/user/update`, {
      displayName: updatedData.displayName,
      autoApproveSocialMedia: updatedData.autoApproveSocialMedia,
    });
    localStorage.setItem(
      AUTH_USER_DATA,
      JSON.stringify(response.data.updatedUserData)
    );

    return response.data.updatedUserData;
  } catch (error) {
    console.error("Error updating user data:", error);
  }
};

FirebaseUserService.getUserPaymentHistoryData = async () => {
  try {
    const authUserData = JSON.parse(localStorage.getItem(AUTH_USER_DATA));
    if (!authUserData) return null;

    const { owner_uid } = authUserData;
    const userDataDocRef = doc(db, `users/${owner_uid}`);
    const userDataSnapshot = await getDoc(userDataDocRef);

    if (userDataSnapshot.exists()) {
      const paymentHistoryCollectionRef = collection(
        db,
        `users/${owner_uid}/paymentHistory`
      );
      const paymentHistorySnapshot = await getDocs(
        query(paymentHistoryCollectionRef, orderBy("dateCreated", "desc"))
      );

      if (!paymentHistorySnapshot.empty) {
        const paymentHistory = paymentHistorySnapshot.docs.map(doc =>
          doc.data()
        );
        return paymentHistory;
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (error) {
    console.log(`Error getUserPaymentHistoryData`, error);
    throw error;
  }
};

export default FirebaseUserService;
