import AxiosInstance from "auth/SQLInterceptor";
import { delay } from "utils/other";
import SQLMyProjectsService from "./SQLMyProjectsService";

const SQLArticlesService = {};

SQLArticlesService.fetchArticles = async (
  projectId,
  currentPage,
  sortField,
  sortOrder,
  searchField
) => {
  try {
    await delay();
    // console.log(`currentPage`, currentPage);
    // console.log(`sortField`, sortField);
    // console.log(`sortOrder`, sortOrder);
    // console.log(`searchField`, searchField);

    const params = {
      page: currentPage,
    };

    if (sortField) {
      params.sortField = sortField;
    }

    if (sortOrder) {
      params.sortOrder = sortOrder;
    }

    if (searchField.length > 0) {
      params.searchField = searchField;
    }

    const result = await AxiosInstance.get(`/articles/${projectId}`, {
      params,
    });
    return result.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};

SQLArticlesService.getArticlesCounts = async projectId => {
  try {
    const result = await AxiosInstance.get(`/articles/counts/${projectId}`);

    return result.data;
  } catch (error) {
    throw error;
  }
};

SQLArticlesService.getArticleById = async (projectId, articleId) => {
  try {
    await delay();
    const result = await AxiosInstance.get(
      `/articles/${projectId}/${articleId}`
    );
    return result.data.article;
  } catch (error) {
    console.error("Error fetching article:", error);
    throw error;
  }
};

SQLArticlesService.updateArticleById = async (projectId, articleId, data) => {
  try {
    const result = await AxiosInstance.put(
      `/articles/${projectId}/${articleId}`,
      data
    );
    return result.data.article;
  } catch (error) {
    console.error("Error fetching article:", error);
    throw error;
  }
};

SQLArticlesService.hideArticleById = async articleId => {
  try {
    const result = await AxiosInstance.delete(`/articles/${articleId}`);

    return result.data.article;
  } catch (error) {
    console.error("Error deleting article:", error);
    throw error;
  }
};

SQLArticlesService.ensureCredentialsForPublishing = async projectId => {
  const credentials = {
    twitter: false,
    facebook: false,
    site: false,
  };
  try {
    const project = await SQLMyProjectsService.getProjectById(projectId);

    if (project) {
      const { websiteSettings, socialMediaSettings } = project;

      if (websiteSettings[0]?.siteUrl && websiteSettings[0]?.name) {
        credentials.site = true;
      }

      if (socialMediaSettings?.some(social => social.type === "Twitter/X")) {
        credentials.twitter = true;
      }

      if (socialMediaSettings?.some(social => social.type === "Facebook")) {
        credentials.facebook = true;
      }
    }
    return credentials;
  } catch (error) {
    console.error("Error ensure credentials for publishing:", error);
    return credentials;
  }
};

SQLArticlesService.makeArticlePublish = async data => {
  try {
    const result = await AxiosInstance.post(`/articles/publish`, data);
    return result.data;
  } catch (error) {
    console.error("Error making article publish:", error);
    throw error;
  }
};

SQLArticlesService.postArticle = async data => {
  try {
    const result = await AxiosInstance.post(`/articles/create`, data);
    return result.data;
    // return;
  } catch (error) {
    throw error;
  }
};

SQLArticlesService.createPreview = async data => {
  try {
    const result = await AxiosInstance.post(`/articles/create-preview`, data);
    return result.data;
  } catch (error) {
    throw error;
  }
};

SQLArticlesService.interceptPreview = async data => {
  try {
    const result = await AxiosInstance.post(
      `/articles/intercept-preview`,
      data
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export default SQLArticlesService;
