import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(weekday);
dayjs.extend(isoWeek);

const getAdjustedWeekOfMonth = date => {
  const startOfMonth = date.startOf("month");
  const dayOfMonth = date.date();
  const dayOfWeek = startOfMonth.day();

  // Определяем номер недели
  return Math.ceil((dayOfWeek + dayOfMonth) / 7);
};

export default getAdjustedWeekOfMonth;
