import React from "react";
import {
  AUTH_PREFIX_PATH,
  APP_PREFIX_PATH,
  UNAUTHENTICATED_ENTRY,
} from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "main",
    path: `${UNAUTHENTICATED_ENTRY}`,
    component: React.lazy(() => import("views/auth-views/authentication/main")),
  },
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "register",
    path: `${AUTH_PREFIX_PATH}/register`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "privacy-policy",
    path: `${UNAUTHENTICATED_ENTRY}/privacy-policy`,
    component: React.lazy(() => import("views/auth-views/privacy-policy")),
  },
  {
    key: "terms-of-service",
    path: `${UNAUTHENTICATED_ENTRY}/terms-of-service`,
    component: React.lazy(() => import("views/auth-views/terms_of_service")),
  },
];

export const protectedRoutes = [
  {
    key: "error-page-1",
    path: `${APP_PREFIX_PATH}/error-page-404`,
    component: React.lazy(() =>
      import("views/app-views/errors/error-page-404")
    ),
  },
  {
    key: "dashboard.default",
    // path: `${APP_PREFIX_PATH}/dashboards/default`,
    path: `${APP_PREFIX_PATH}/dashboards`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  {
    key: "pages.projects",
    path: `${APP_PREFIX_PATH}/projects`,
    component: React.lazy(() => import("views/app-views/pages/projects/")),
  },
  {
    key: "pages.projects-list",
    path: `${APP_PREFIX_PATH}/projects/projects-list`,
    component: React.lazy(() =>
      import("views/app-views/pages/projects/projects-list")
    ),
  },
  {
    key: "pages.projects-add",
    path: `${APP_PREFIX_PATH}/projects/add`,
    component: React.lazy(() =>
      import("views/app-views/pages/projects/projects-add")
    ),
  },
  {
    key: "pages.projects-edit",
    path: `${APP_PREFIX_PATH}/projects/edit/:id`,
    component: React.lazy(() =>
      import("views/app-views/pages/projects/projects-add")
    ),
  },
  {
    key: "pages.projects-articles",
    path: `${APP_PREFIX_PATH}/projects-list/:id/articles`,
    component: React.lazy(() => import("views/app-views/pages/articles")),
  },
  {
    key: "pages.projects-articles",
    path: `${APP_PREFIX_PATH}/projects-list/:id/articles/:arId`,
    component: React.lazy(() =>
      import("views/app-views/pages/articles/article/")
    ),
  },
  {
    key: "pages.settings",
    path: `${APP_PREFIX_PATH}/settings`,
    component: React.lazy(() => import("views/app-views/pages/setting")),
  },

  {
    key: "pages.payments",
    path: `${APP_PREFIX_PATH}/payments`,
    component: React.lazy(() => import("views/app-views/pages/payments")),
  },

  {
    key: "pages.payment",
    path: `${APP_PREFIX_PATH}/payment`,
    component: React.lazy(() => import("views/app-views/pages/payment")),
  },
];
