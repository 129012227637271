import axios from "axios";
import { API_BASE_URL } from "constants/ApiConstant";
import { getAuthToken } from "./FirebaseAuth";
import { delay } from "utils/other";

const AxiosInstance = axios.create({
  baseURL: API_BASE_URL,
  // timeout: 10000,
});

// AxiosInstance.interceptors.request.use(
//   async config => {
//     const token = await getAuthToken();
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// Добавляем интерсептор перед каждым запросом
AxiosInstance.interceptors.request.use(
  async config => {
    // Проверяем, есть ли уже заголовок Authorization
    if (!config.headers.Authorization) {
      await delay(1000);
      const token = await getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Добавляем интерсептор для обработки ошибок ответа
AxiosInstance.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true; // Помечаем запрос как повторный
      try {
        await delay(2000);
        const newToken = await getAuthToken();
        if (newToken) {
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return AxiosInstance.request(originalRequest);
        }
      } catch (tokenError) {
        return Promise.reject(tokenError);
      }
    }

    return Promise.reject(error);
  }
);

export default AxiosInstance;
