import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SQLArticlesService from "services/SQLArticlesService";
import { formatErrorMessage } from "services/utils";

export const initialState = {
  loading: true,
  articlesMessage: "",
  messageType: "",
  showMessage: false,
  redirect: "",
  data: [],
  totalArticles: 1,
};

export const fetchArticles = createAsyncThunk(
  "articles/",
  async (
    { projectId, currentPage, sortField, sortOrder, searchField },
    { rejectWithValue }
  ) => {
    try {
      const articlesData = await SQLArticlesService.fetchArticles(
        projectId,
        currentPage,
        sortField,
        sortOrder,
        searchField
      );

      return articlesData;
    } catch (err) {
      switch (true) {
        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    showArticlesMessage: (state, action) => {
      state.articlesMessage = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideArticlesMessage: state => {
      state.articlesMessage = "";
      state.messageType = "";
      state.showMessage = false;
    },
    showLoading: state => {
      state.loading = true;
    },
    clearRedirect: state => {
      state.redirect = "";
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchArticles.pending, state => {
        state.loading = true;
      })
      .addCase(fetchArticles.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.articles;
        state.totalArticles = Number(action.payload.totalArticles)
          ? Number(action.payload.totalArticles)
          : 1;
      })
      .addCase(fetchArticles.rejected, (state, action) => {
        state.articlesMessage = action.payload;
        state.messageType = "error";
        state.showMessage = true;
        state.loading = false;
      });
  },
});
export const {
  showArticlesMessage,
  hideArticlesMessage,
  showLoading,
  clearRedirect,
} = articlesSlice.actions;

export default articlesSlice.reducer;
