import dayjs from "dayjs";

const calculateDateDifference = dateString => {
  const currentDate = dayjs(); // Get the current date
  const targetDate = dayjs(dateString); // Convert the string to a date

  // Calculate the difference in days
  let differenceInDays = targetDate.diff(currentDate, "day");

  if (differenceInDays >= 0 && !targetDate.isSame(currentDate, "day")) {
    differenceInDays += 1;
  }

  return differenceInDays;
};

export default calculateDateDifference;
