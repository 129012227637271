const getCountBySubjectId = (subjectId, arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].subjectId === subjectId) {
      return arr[i].count;
    }
  }

  return 0;
};

export default getCountBySubjectId;
