import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";

import firebaseConfig from "configs/FirebaseConfig";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "firebase/auth";
// import "firebase/compat/auth";
// import "firebase/compat/firestore";

const { REACT_APP_VAPID_KEY } = process.env;

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);
const currentUser = auth.currentUser;
const googleAuthProvider = new GoogleAuthProvider();
const facebookAuthProvider = new FacebookAuthProvider();
const messaging = getMessaging(firebaseApp);

const generateToken = async () => {
  const permisson = await Notification.requestPermission();
  console.log(permisson);
  if (permisson === "granted") {
    const token = await getToken(messaging, { vapidKey: REACT_APP_VAPID_KEY });
    console.log(token);
  }
};

const getAuthToken = async () => {
  try {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      console.log(`No user found in getAuthToken`);
      return null;
    }
    const idToken = await user.getIdToken();

    return idToken;
  } catch (error) {
    console.log(`Error in getting auth token: ${error}`);
    return null;
  }
};

const onMessageListener = message =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      const uniqueErrorKey = new Date().getTime();
      //  message from ant design
      console.log(`main push notificator activated`);
      message[payload.notification.title]({
        content: payload.notification.body,
        duration: 0,
        key: uniqueErrorKey,
        onClick: () => message.destroy(uniqueErrorKey),
      });
      if (payload?.data?.data) {
        const result = JSON.parse(payload.data.data);
        console.log(`result`, result);
      }
    });
  });

const onBackgroundMessageListener = () =>
  new Promise(resolve => {
    onBackgroundMessage(messaging, payload => {
      console.log("onBackgroundMessage", payload);
      resolve(payload);
    });
  });

export {
  generateToken,
  getAuthToken,
  onMessageListener,
  onBackgroundMessageListener,
  db,
  auth,
  currentUser,
  googleAuthProvider,
  GoogleAuthProvider,
  facebookAuthProvider,
  FacebookAuthProvider,
  messaging,
  signInWithEmailAndPassword,
  signOut,
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile,
  sendPasswordResetEmail,
  sendEmailVerification,
};
