import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(weekday);
dayjs.extend(isoWeek);

const getWeekDayAndWeekOfMonth = dateString => {
  const date = dayjs(dateString);

  const dayOfWeek = date.format("dddd"); // ( "Monday")

  // Находим первый день месяца
  const firstDayOfMonth = date.startOf("month");

  // Определяем номер недели в месяце
  const dayOfMonth = date.date();
  const weekOfMonth = Math.ceil((dayOfMonth + firstDayOfMonth.day()) / 7);

  // Возвращаем строку в нужном формате
  return `Monthly on ${dayOfWeek} of every ${weekOfMonth}-rd week`;
};

export default getWeekDayAndWeekOfMonth;
