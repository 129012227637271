import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { formatErrorMessage } from "services/utils";
import isEqual from "lodash/isEqual";
import SQLMyProjectsService from "services/SQLMyProjectsService";

export const initialState = {
  loading: false,
  projectMessage: "",
  messageType: "",
  showMessage: false,
  redirect: "",
  data: [],
};

export const fetchProjects = createAsyncThunk(
  "projects/",
  async (_, { rejectWithValue }) => {
    try {
      return await SQLMyProjectsService.fetchProjects();
    } catch (err) {
      switch (true) {
        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const deleteProject = createAsyncThunk(
  "projects/deleteProject",
  async (projectId, { rejectWithValue }) => {
    try {
      return await SQLMyProjectsService.deleteProject(projectId);
    } catch (err) {
      switch (true) {
        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const addProject = createAsyncThunk(
  "projects/addProject",
  async (data, { rejectWithValue }) => {
    try {
      return await SQLMyProjectsService.addProject(data);
    } catch (err) {
      switch (true) {
        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const editProject = createAsyncThunk(
  "projects/editProject",
  async (data, { rejectWithValue }) => {
    try {
      return await SQLMyProjectsService.editProject(data);
    } catch (err) {
      switch (true) {
        default:
          return rejectWithValue(
            err?.message ? formatErrorMessage(err?.message) : "Error!!"
          );
      }
    }
  }
);

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    showProjectsMessage: (state, action) => {
      state.projectMessage = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideProjectsMessage: state => {
      state.projectMessage = "";
      state.messageType = "";
      state.showMessage = false;
    },
    showLoading: state => {
      state.loading = true;
    },
    clearRedirect: state => {
      state.redirect = "";
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProjects.pending, state => {
        state.loading = true;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.loading = false;

        if (!isEqual(state.data, action.payload)) {
          state.data = action.payload;
        }
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.projectMessage = action.payload;
        state.messageType = "error";
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(addProject.pending, state => {
        state.loading = true;
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.loading = false;
        state.projectMessage = "New project successfully added.";
        state.messageType = "success";
        state.showMessage = true;
        state.redirect = "/app/projects/projects-list";
      })
      .addCase(addProject.rejected, (state, action) => {
        state.projectMessage = action.payload;
        state.messageType = "error";
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(editProject.pending, state => {
        state.loading = true;
      })
      .addCase(editProject.fulfilled, (state, _) => {
        state.projectMessage = `Changes saved successfully.`;
        state.messageType = "success";
        state.showMessage = true;
        state.loading = false;
        state.redirect = "/app/projects/projects-list";
      })
      .addCase(editProject.rejected, (state, action) => {
        state.projectMessage = action.payload;
        state.messageType = "error";
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(deleteProject.pending, state => {
        state.loading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        console.log(`action`, action);
        state.projectMessage = `Project successfully deleted.`;
        state.messageType = "success";
        state.showMessage = true;
        state.loading = false;
        state.data = state.data.filter(item => item.id !== action.payload);
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.projectMessage = action.payload;
        state.messageType = "error";
        state.showMessage = true;
        state.loading = false;
      });
  },
});
export const {
  showProjectsMessage,
  hideProjectsMessage,
  showLoading,
  clearRedirect,
} = projectSlice.actions;

export default projectSlice.reducer;
