const checkAntDropdownHiddenExistence = () => {
  let dropdownElements = document.querySelectorAll("div.ant-dropdown");

  for (let element of dropdownElements) {
    if (!element.classList.contains("ant-dropdown-hidden")) {
      return true;
    }
  }
  return false;
};

export default checkAntDropdownHiddenExistence;
